<template>
  <div>
    <!-- Iterate over the filtered header items -->
    <wrapper-form-row
      v-for="(headerItem, i) in filterHeadersByFlexItem"
      :key="`rowId-${rowId}-${i}`"
      :item="headerItem">
      <!-- AppFormRow for each header item -->
      <template #default="{ header, single }">
        <AppFormRow
          :filter-names-group-items="filterNamesGroupItems"
          :single-item="single"
          class="row-form"
          wrapper-class="mb-12"
          text-class="row-data__header"
          :header-text="header.value"
          :split-header-and-cell="isAsideHeader(header.text)">
          <!-- Additional content inside AppFormRow -->
          <template #textAdditional>
            <!-- Comment badge for the header item -->
            <CommentBadge
              :data-test="header.dataTest"
              :item="{
                resolvedComments: true,
                ...getCellPointerAmount(header.value),
              }"
              :class="[!getCellPointerAmount(header.value).commentsAmount ? 'v-badge__expanded-hide' : '', {'badged-comment': single }]"
              class="v-badge__expanded"
              @click="setCellPointer(header.value)" />
          </template>
          <!-- Row cell (ProductCell) for the header item -->
          <template #rowCell>
            <ProductCell
              v-if="header.column.type"
              :header="header"
              class="pa-0"
              open-link-in-new-tab
              :disabled-edit-mode="disabledEditMode"
              :expanded-row="!showPensil(header.column.type)"
              :should-expand-height="isAsideHeader(header.text)"
              :item="item"
              :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }"
              @saveFileProcess="saveFileProcess"
              v-on="$listeners" />
          </template>
        </AppFormRow>
      </template>
    </wrapper-form-row>
  </div>
</template>
<script>
import CommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';
import AppFormRow from '@/components/App/AppFormRow';
import ProductCell from '@/components/Product/ProductCell';
import renderingItemsWithDataTest from '@/utils/renderingItemsWithDataTest';
import WrapperFormRow from '@/components/App/AppFormRow/WrapperFormRow';
import { filterHeadersGroup } from '@/utils';
import { isAsideHeaderName } from '@/utils/productHeaders';
const { mapGetters, mapState, mapMutations } = require('vuex');
export default {
  name: 'ProductCellProjectsFormList',
  components: {
    WrapperFormRow,
    AppFormRow,
    ProductCell,
    CommentBadge,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({
      }),
    },
    view: {
      type: String,
      default: 'productCreate',
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Comments', ['commentCellPointers']),
    ...mapGetters(['userId']),
    groupItems() {
      return [{
        items: ['Image', 'Line Drawing'],
      }];
    },
    filterNamesGroupItems() {
      return this.groupItems.reduce((result, { items }) => {
        return [...result, ...items];
      }, []);
    },
    filterHeadersByFlexItem() {
      const { renderingItemsWithDataTest: headers, groupItems } = this;
      return filterHeadersGroup({
        headers,
        groupItems,
        disabled: false,
      });
    },
    renderingItemsWithDataTest() {
      return renderingItemsWithDataTest({
        items: this.headers, arg: 'text', before: 'row_comments_', after: '_category',
      });
    },
    rowId() {
      const { id } = this.item || {
      };
      return id;
    },
  },
  methods: {
    ...mapMutations({
      setCellPointer: 'ProjectDetailsTableSchedule/setCellPointer',
    }),
    saveFileProcess(e = false) {
      this.$emit('saveFileProcess', e);
    },
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'FIELD_PRODUCT_REFERENCE', 'FIELD_PRODUCT_EQUAL', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    getCellPointerAmount(name) {
      const cellPointerObj = this.commentCellPointers.find(pointer => pointer.name === name);
      return cellPointerObj ? {
        commentsAmount: cellPointerObj.amount,
        unresolved: cellPointerObj.unresolved,
      } : {
      };
    },
    isAsideHeader(headerText) {
      return isAsideHeaderName(headerText);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-badge__expanded-hide {
  visibility: hidden;
}
.badged-comment {
  margin-top: 5px;
}
.row-form:hover .v-badge__expanded-hide {
  visibility: visible;
}
</style>
