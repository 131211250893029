<template>
  <v-card
    class="px-sm-6 px-3 py-10 h-inherit">
    <app-search-rows-provider
      placeholder="Search by field name"
      input-class="mb-12"
      cols="11"
      wrapper-class="h-inherit"
      :items="renderHeaders">
      <template #items="{ searchItems }">
        <ProductCellFormList
          type="projects"
          :headers="searchItems"
          :disabled-edit-mode="disabledEditMode"
          :item="item"
          :view="''"
          @saveFileProcess="saveFileProcess"
          v-on="$listeners" />
      </template>
    </app-search-rows-provider>
  </v-card>
</template>
<script>
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellProjectsFormList';
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import { sortAsideHeadersLast } from '@/utils/productHeaders';
import {
  mapState, mapGetters,
} from 'vuex';
export default {
  name: 'RowDataList',
  components: {
    ProductCellFormList,
    AppSearchRowsProvider,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      tableHeaders: 'ScheduleViews/tableHeaders',
    }),
    ...mapGetters('FeatureFlags', ['moveTipsAndTricks']),
    ...mapState('Comments', ['commentCellPointers']),
    filteredHeaders() {
      return this.tableHeaders.filter(header => header.value !== 'order');
    },
    renderHeaders() {
      return this.filteredHeaders.toSorted(sortAsideHeadersLast);
    },
  },
  methods: {
    saveFileProcess(e = false) {
      this.$emit('saveFileProcess', e);
    },
  },
};
</script>
